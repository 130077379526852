import React, { FC, useEffect, useState } from "react";
import { PaymentDetails } from '../../@types';
import { NO_MACHINE_ERROR_BODY, UNIT_NOT_REGISTERED } from '../../constants';
import { Dashboard, DashboardOption } from '../Dashboard';
import PoolTableCommunication from '../PoolTableCommunication';
import { Payment } from '../Payment';
import { StyledNoUnit } from '../Containers/UnitsContainer/styles';
import PlaceholderIconSvg from '../PlaceholderIcon';
import MessageNotification from '../Message';
import LoadingComponent from '../Loading';
import { FruitMachineStyles } from './styles';


enum FruitFlow {
  DASHBOARD = 'Dashboard',
  POOL_TABLE = 'Pool',
  PAYMENT = 'Payment'
}

const SUCCESS_SCREEN_DURATION = 3000;

interface Props {
  unitId: string;
  userId: string;
  unitDetails: any;
  unitDetailsType: String;
  generalLoading: boolean;
  cashflowPaymentLink: string;
  paymentNonce?: PaymentDetails | null;
  handleNonce: Function;
  onPaymentSuccess: Function;
  onPaymentFailed: Function;
  onFlowComplete: Function;
  onCollectWinnings: Function;
  transactionStatus?: string | null;
  transactionAmount?: number;
  paymentJobReference?: string | null;
  winnings?: number | null;
}

export const FruitMachineFlow: FC<Props> = ({
                                              unitDetailsType,
                                              unitDetails,
                                              unitId,
                                              userId,
                                              generalLoading,
                                              paymentNonce,
                                              cashflowPaymentLink,
                                              handleNonce,
                                              onPaymentSuccess,
                                              onPaymentFailed,
                                              onFlowComplete,
                                              onCollectWinnings,
                                              paymentJobReference,
                                              transactionStatus,
                                              winnings,
                                              transactionAmount
                                            }) => {

  const isDemoUnit = () => (unitDetailsType === "AMUSE_NON_GAMB" || unitDetailsType === "AMUSE_GAMB") && unitId === 'disabled';

  const [balance, setBalance] = useState(50.00);
  const [flowStep, setFlowStep] = useState(isDemoUnit() ? FruitFlow.DASHBOARD : FruitFlow.POOL_TABLE);

  /**
   * Because there is no routing logic configured, elements are displayed with rendering logic (yuch!)
   */
  const shouldShowDemoDashboard = () => flowStep === FruitFlow.DASHBOARD;

  /**
   * Because there is no routing logic configured, elements are displayed with rendering logic (yuch!)
   */
  const shouldShowPoolTableConnection = () => flowStep === FruitFlow.POOL_TABLE;

  /**
   * Conditions for showing the payment screen
   */
  const shouldShowPaymentScreen = () => flowStep === FruitFlow.PAYMENT;

  const handleDashboardClick = (navigation: string) => {
    switch (navigation) {
      case DashboardOption.WHERE_TO_BUY:
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      case DashboardOption.WINNINGS:
        updateBalance(winnings ? balance + winnings : balance);
        if (winnings) {
          onCollectWinnings();
        }
        break;
      case DashboardOption.LOYALTY:
        updateBalance(50.00);
        break;
    }
  }

  const handlePaymentDetails = (nonce: PaymentDetails) => {
    handleNonce(nonce);
  }

  useEffect(() => {
    switch (transactionStatus) {
      case 'warning':
        setFlowStep(FruitFlow.PAYMENT);
        break;
      case 'denied':
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      case 'success':
        setFlowStep(FruitFlow.POOL_TABLE);
        setTimeout(() => {
          isDemoUnit() ? setFlowStep(FruitFlow.DASHBOARD) : setFlowStep(FruitFlow.POOL_TABLE);
          // Commented because screen should stay with Thank You title
          // onFlowComplete();
        }, SUCCESS_SCREEN_DURATION);
        break;
      case 'timeout':
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      default:
        isDemoUnit() ? setFlowStep(FruitFlow.DASHBOARD) : setFlowStep(FruitFlow.POOL_TABLE);
        break;
    }
  }, [transactionStatus, paymentJobReference, isDemoUnit()]);


  /**
   * Initializes the persisted balance in order to allow for a continuous demo flow
   *
   * The operations made on the balance are persisted in localStorage and as such, they are read from there
   */
  useEffect(() => {
    const savedBalance = localStorage.getItem("balance");
    if (savedBalance) {
      setBalance(parseFloat(savedBalance));
    } else {
      localStorage.setItem("balance", '50.00');
    }
  }, []);

  useEffect(() => {
    if (transactionAmount) {
      updateBalance(balance - transactionAmount)
    }
  }, [transactionAmount])

  /**
   * Updates the balance and also persists it to localStorage
   * @param newBalance the new balance amount
   */
  const updateBalance = (newBalance: number) => {
    setBalance(newBalance);
    localStorage.setItem("balance", newBalance.toFixed(2));
  }

  return (
    <FruitMachineStyles>
      <div className="machines-wrapper new-flow">
        {shouldShowDemoDashboard() &&
        <Dashboard balance={balance}
                   winnings={winnings}
                   onClick={handleDashboardClick}/>
        }
        {shouldShowPoolTableConnection() &&
        <PoolTableCommunication handleNonce={(nonce => handlePaymentDetails(nonce))}
                                unitDetails={unitDetails}
                                showGeneralLoading={generalLoading}
                                userId={userId}
                                demo={true}
                                cashflowPaymentLink={cashflowPaymentLink}/>
        }
        {shouldShowPaymentScreen() &&
        <Payment nonce={paymentNonce}
                 paymentJobReference={paymentJobReference}
                 generalLoading={generalLoading}
                 onPaymentSuccess={onPaymentSuccess}
                 onPaymentFailed={onPaymentFailed}/>
        }
        {(!unitDetailsType || unitDetailsType === '') &&
        unitDetails.flowStatus === 'UNIT_NOT_REGISTERED' &&
        !generalLoading &&
        <StyledNoUnit>
          <div className="placeholder-wrapper"><PlaceholderIconSvg/></div>
          <MessageNotification messageType='error'
                               messageTitle={UNIT_NOT_REGISTERED}
                               messageBody={NO_MACHINE_ERROR_BODY}/>
        </StyledNoUnit>}
        {(!unitDetailsType || unitDetailsType === '') && generalLoading &&
        <div style={{display: "flex", justifyContent: 'center', flexGrow: 1}}>
          <LoadingComponent displayLoadingMessage={true}/>
        </div>}
      </div>
    </FruitMachineStyles>
  )
}
